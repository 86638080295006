// COPYRIGHT SMARTTRACK

/**
 * State
 */

let demoLiveQuery;
let demoTitle;
let demoContent;
let globalAssetDict;


/**
 * Handlers
 */

exports.handleSMS = async () => {

	// Check if valid phone number
	const isPhoneValid = $('#demo-phone').intlTelInput('isValidNumber');
	if (isPhoneValid === true) {

		// Get E164 phone number
		const phone = $('#demo-phone').intlTelInput('getNumber', intlTelInputUtils.numberFormat.E164);

		// Send download link to phone
		try {
			let result = await Parse.Cloud.run('sendDownloadLinkForTarget', { phone });

			// Create new demo and include code
			try {
				result = await Parse.Cloud.run('createDemoWithParameters', { includeCode: true });

				// Initialize asset array
				globalAssetDict = {};

				// Get global references
				demoTitle = $('#demo-title').html();
				demoContent = $('#demo-content').html();

				// Fade out demo section
				$('#demo-section').fadeOut(() => {

					// Update demo section to instructions
					$('#demo-title').html("Great! We've Sent a Download Link to Your Phone.");
					$('#demo-content').html(`Once ${process.env.ENTITY_OBJECT.NAME} has finished downloading, open it up and press the &#34;Try the ${process.env.ENTITY_OBJECT.NAME} Demo&#34; button. Enter the code <span class='highlight-span'>${result.code}</span> to get started.`);
					$('.intl-tel-input').css({ display: 'none' });
					$('#demo-phone').val('');
					$('#demo-button').css({ display: 'none' });
					$('#reset-button').css({ display: 'inline-block' });

					// Fade in demo section
					$('#demo-section').fadeIn();
				});

				// Update demo code field
				$('#demo-code-field').fadeOut(function () {
					$(this).text(result.code).css({ opacity: 1.0 }).fadeIn();
				});

				// Start clean
				try {
					await Parse.LiveQuery.close();
				} catch (e) {}

				// Create live query
				const demoQuery = new Parse.Query('demo');
				demoQuery.equalTo('code', result.code);
				demoQuery.exists('started');
				demoQuery.doesNotExist('ended');
				demoQuery.equalTo('active', true);
				demoQuery.greaterThan('expiration', new Date());

				// Subscribe to live query
				demoLiveQuery = await demoQuery.subscribe();

				// Listen for open state
				demoLiveQuery.on('open', () => {

					// Add live listeners
					demoLiveQuery.on('enter', (demo) => {
						if (demo.get('started') != null) {

							// Update phone mockup
							$('#phone-instruction-mockup').addClass('phone-mockup-fade-out');
							$('#phone-mockup').addClass('phone-mockup-fade-in');

							// Fade out demo section
							$('#demo-section').fadeOut(() => {

								// Update demo section to demo started
								$('#demo-title').html('Great! Time to Scan Your First Asset.');
								$('#demo-content').html(`Try scanning one of the demo assets on the right to add it to your library. You can always view your asset library by tapping on the &#34;Search&#34; tab on the ${process.env.ENTITY_OBJECT.NAME} app.`);

								// Fade in demo section
								$('#demo-section').fadeIn();
							});
						}
					});
					demoLiveQuery.on('update', (demo) => {
						if (demo.get('assets') != null) {

							// Check expiration
							const now = new Date();
							if (new Date(demo.get('expiration')).getTime() > now.getTime()) {

								// Get assets
								const assets = demo.get('assets');

								// Get length of asset dictionary
								let assetCount = 0;
								Object.keys(globalAssetDict).forEach((i) => {
									if (Object.prototype.hasOwnProperty.call(globalAssetDict, i)) assetCount += 1;
								});

								// Update demo section if first asset
								if (assets.length === 1 && assetCount === 0) {

									// Fade out demo section
									$('#demo-section').fadeOut(() => {

										// Update demo section to demo first asset scanned
										$('#demo-title').html(`Awesome! You've Scanned Your First Asset with ${process.env.ENTITY_OBJECT.NAME}.`);
										$('#demo-content').html(`With ${process.env.ENTITY_OBJECT.NAME}, you can scan and inventory books, electronics, musical instruments, consumables, and more using the ${process.env.ENTITY_OBJECT.NAME} app. Feel free to explore, and just tap the &#34;Back to Sign In&#34; button to finish the demo.`);

										// Fade in demo section
										$('#demo-section').fadeIn();
									});
								}

								// Check for new asset
								for (let i = 0; i < assets.length; i += 1) {
									if (!globalAssetDict[assets[i].asset.id]) {
										const { identifier } = assets[i].asset;

										// Animate barcode
										$(`#barcode-${identifier}`).addClass('barcode-bounce');
										setTimeout(() => {
											$(`#barcode-${identifier}-text`).css('box-shadow', '0px 5px 20px rgba(0,0,0,0.22)');
											$(`#barcode-${identifier}-text`).css('width', '100%');
											$(`#barcode-${identifier}-text`).css('margin-top', -40);
										}, 400);

										// Add asset to dictionary
										globalAssetDict[assets[i].asset.id] = assets[i].asset;
									}
								}
							} else { // Unsubscribe from connection
								demoLiveQuery.unsubscribe();
							}
						}
					});
					demoLiveQuery.on('leave', () => {

						// Finish demo
						exports.finishDemo();
					});
				});
			} catch (error) { // Finish demo
				exports.finishDemo();
			}
		} catch (error) { // Finish demo
			exports.finishDemo();
		}
	}
};

exports.finishDemo = () => {

	// Unsubscribe from connection
	if (demoLiveQuery) demoLiveQuery.unsubscribe();

	// Update phone mockup
	$('#phone-instruction-mockup').removeClass('phone-mockup-fade-out').addClass('phone-mockup-fade-in');
	$('#phone-mockup').removeClass('phone-mockup-fade-in').addClass('phone-mockup-fade-out');

	// Fade out demo section
	$('#demo-section').fadeOut(() => {

		// Update demo section
		$('#demo-title').html(`Thanks for Trying the ${process.env.ENTITY_OBJECT.NAME} Demo!`);
		$('#demo-content').html(`Click the button below to create your ${process.env.ENTITY_OBJECT.NAME} account and start managing your inventory. <a href='/faq' style='color:white; text-decoration:underline;'>Still have questions?</a>`);
		$('#signup-button').css({ display: 'inline-block' });
		$('#back-button').css({ display: 'inline-block' });
		$('#reset-button').css({ display: 'none' });

		// Reset classes
		$('.barcode').removeClass('barcode-bounce');
		$('.barcode-text-slide').css('box-shadow', 'none').css('width', '90%').css('margin-top', '10px');

		// Fade in demo section
		$('#demo-section').fadeIn();
	});
};

exports.resetDemo = () => {

	// Unsubscribe from connection
	if (demoLiveQuery) demoLiveQuery.unsubscribe();

	// Fade out demo section
	$('#demo-section').fadeOut(() => {

		// Update demo section
		$('#demo-title').html(demoTitle);
		$('#demo-content').html(demoContent);
		$('.intl-tel-input').css({ display: 'inline-block' });
		$('#demo-button').css({ display: 'inline-block' });
		$('#reset-button').css({ display: 'none' });
		$('#signup-button').css({ display: 'none' });
		$('#back-button').css({ display: 'none' });

		// Reset classes
		$('#phone-instruction-mockup').removeClass('phone-mockup-fade-in');
		$('#phone-mockup').removeClass('phone-mockup-fade-out');

		// Fade in demo section
		$('#demo-section').fadeIn();
	});

	// Update demo code field
	$('#demo-code-field').fadeOut(function () {
		$(this).text('Your Code').css({ opacity: 0.3 }).fadeIn();
	});
};
