// COPYRIGHT SMARTTRACK

/**
 * Handlers
 */

const TxtType = function (el, toRotate, period) { // Create typewriter object
	this.toRotate = toRotate;
	this.el = el;
	this.loopNum = 0;
	this.period = parseInt(period, 10) || 2000;
	this.txt = '';
	this.isDeleting = false;
	this.tick = function () { // Set up typewriter

		// Create typewriter parameters
		const i = this.loopNum % this.toRotate.length;
		const fullTxt = this.toRotate[i];

		// Check deleting status
		if (this.isDeleting) {
			this.txt = fullTxt.substring(0, this.txt.length - 1);
		} else {
			this.txt = fullTxt.substring(0, this.txt.length + 1);
		}

		// Set tick content
		this.el.innerHTML = this.txt;

		// Create tick paramteters
		const that = this;
		let delta = (200 - Math.random() * 100) * 0.4;
		if (this.isDeleting) { delta *= 0.5; }

		// Set tick parameters
		if (!this.isDeleting && this.txt === fullTxt) {
			delta = this.period;
			this.isDeleting = true;
		} else if (this.isDeleting && this.txt === '') {
			this.isDeleting = false;
			this.loopNum += 1;
			delta = 500;
		}

		// Set timeout for next tick
		setTimeout(() => {
			that.tick();
		}, delta);
	};
	this.tick();
};


/**
 * Init
 */

exports.initialize = function () {

	// Listen for onload
	$(document).ready(() => {

		// Get elements
		const typeElements = document.getElementsByClassName('typewrite');

		// Create typewriters for elements
		for (let i = 0; i < typeElements.length; i += 1) {
			const toRotate = typeElements[i].getAttribute('data-type');
			const period = typeElements[i].getAttribute('data-period');
			if (toRotate) { // eslint-disable-next-line no-new
				new TxtType(typeElements[i], JSON.parse(toRotate), period);
			}
		}

		// Insert cursor
		const css = document.createElement('style');
		css.type = 'text/css';
		css.innerHTML = '.typewrite > .wrap { border-right: 0.08em solid white}';
		document.body.appendChild(css);
	});
};
