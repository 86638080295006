// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// CSS
require('intl-tel-input/build/css/intlTelInput.css');

// Modules
require('intl-tel-input');
const demoModule = require('../modules/demo');
const dynamicModule = require('../modules/dynamic');
const navigationModule = require('../modules/navigation');

// Create autoplay video
let shouldAutoPlay = true;
let isAutoPause = false;
const video = document.getElementById('videoElement');
setTimeout(() => {
	isAutoPause = true;
	video.pause();
}, 1000);


/**
 * Action Handlers
 */

function createActionHandlers() {

	// Handle press listen button
	$('#listenButton').click(() => {
		$('#videoElement').prop('muted', false);
		video.currentTime = 0;
		$('#listenButton').fadeOut('slow', null);
	});

	// Handle pause video
	$('#videoElement').on('pause', () => {
		if (!isAutoPause) {
			shouldAutoPlay = false;
		} else {
			isAutoPause = false;
		}
	});

	// Handle play video
	$('#videoElement').on('play', () => {
		shouldAutoPlay = true;
	});

	// Handle window scroll
	$(window).scroll(function () {
		const hT = $('#videoElement').offset().top;
		const hH = $('#videoElement').outerHeight();
		const wH = $(window).height();
		const wS = $(this).scrollTop();
		if (wS > (hT + hH - wH) && (hT > wS) && (wS + wH > hT + hH)) {
			if (shouldAutoPlay) video.play();
		} else if (!video.paused) {
			isAutoPause = true;
			video.pause();
		}
	});

	// Handle format phone number
	$('#demo-phone').keyup(() => {
		$('#demo-phone').intlTelInput('setNumber', $('#demo-phone').val());
	});

	// Handle click on demo button
	$('#demo-button').click(() => {
		demoModule.handleSMS();
	});

	// Handle click on reset button
	$('#reset-button').click(() => {
		demoModule.finishDemo();
	});

	// Handle click on sign up demo button
	$('#signup-button').click(() => {
		if (process.env.ENTITY === 'keelerqik') {
			window.location = '/enroll/leader';
		} else {
			window.location = '/enroll';
		}
	});

	// Handle click on demo back button
	$('#back-button').click(() => {
		demoModule.resetDemo();
	});
}


/**
 * Input Handlers
 */

function createInputHandlers() {

	// Format phone number
	$('#demo-phone').intlTelInput({
		autoPlaceholder: 'off',
		formatOnDisplay: true,
		utilsScript: '/js/intl-tel-input/utils.js'
	});
}


/**
 * Init
 */

// Initialize navigation
navigationModule.initialize();

// Initialize dynamic typewriter effect
dynamicModule.initialize();

// Create action handlers
createActionHandlers();

// Create input handlers
createInputHandlers();
